import styled from '@emotion/styled'
import React, { memo } from 'react'

export const Icon = memo(function Icon() {
  return (
    <Container
      xmlns="http://www.w3.org/2000/svg"
      width="75.46"
      height="426.667"
      viewBox="0 0 75.46 426.667"
    >
      <path
        d="M426.667,71.886C247.393-107.387,195.407,120.289,0,.176,161.347,183.033,215.125-39.265,426.667,71.886Z"
        transform="translate(0 426.667) rotate(-90)"
      />
    </Container>
  )
})

const Container = styled.svg`
  width: auto;
  height: 26.625rem;

  path {
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`
