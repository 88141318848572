import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Dot } from 'app/components/Common/Dot'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { Wave } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Icon } from './Icon'

const AUTOPLAY_TIMER = 5000

export interface Props {
  cta?: ButtonProps
  images: ImageProps[]
  label?: string
  title?: string
}

export const RoomsPreview = memo(function RoomsPreview({
  cta,
  images,
  label,
  title,
}: Props) {
  if (!images) {
    return null
  }

  if (images.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])
  const timer = useRef<any>()

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (instanceRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1800,
    },
    loop: images.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)

    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Head>
          <FadeInUp>
            <Wave />
          </FadeInUp>

          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}

          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
        </Head>

        <Wrapper>
          <Icon />

          <Carousel ref={sliderRef}>
            {uniq(images).map((item, index) => (
              <Slide key={index} className="keen-slider__slide">
                <Inner style={positionStyle(index)}>
                  <Image
                    alt={loaded[index] || nextLoaded[index] ? item.alt : ''}
                    animationStart={
                      loaded[index] || nextLoaded[index] ? true : false
                    }
                    {...(loaded[index] || nextLoaded[index] ? item : null)}
                  />

                  <Spinner />
                </Inner>
              </Slide>
            ))}
          </Carousel>

          {images.length > 1 ? (
            <Media greaterThanOrEqual="desktopSmall">
              <Arrows>
                <Arrow
                  direction="R"
                  onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.next()
                  }
                />
                <Arrow
                  onClick={(e) =>
                    e.stopPropagation() || instanceRef.current?.prev()
                  }
                />
              </Arrows>
            </Media>
          ) : null}

          {cta ? <CTA variant="advanced" {...cta} /> : null}
        </Wrapper>

        <Media lessThan="desktopSmall">
          <Dots dial={5} row>
            {images.map((_item, index) => (
              <Dot
                key={index}
                className={currentSlide === index ? 'active' : undefined}
                onClick={() => {
                  instanceRef.current?.moveToIdx(index)
                }}
              />
            ))}
          </Dots>
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section``

const Head = styled.div`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  padding: 4.375rem 1.5rem;
  text-align: center;

  svg {
    width: 7.5rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralLight2};
  }

  @media (max-width: 1199px) {
    padding-top: 2.5rem;
    padding-bottom: 0.625rem;

    svg {
      width: 5rem;
    }
  }
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.2rem;
  line-height: 1.25rem;
  margin-bottom: 1.875rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`

const Title = styled.h2`
  max-width: 43.75rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 4.25rem;
  margin: auto;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const Wrapper = styled.div`
  height: 74.4vh;
  max-height: 41.875rem;
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    backface-visibility: hidden;
    opacity: 0.35;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1199px) {
    height: 31.25rem;
    text-align: center;
  }
`

const Carousel = styled.div`
  display: flex;
  height: 100%;
  outline: none;
  overflow: hidden;
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
`

const Arrows = styled.div`
  position: absolute;
  top: 50%;
  right: 5.833vw;
  transform: translateY(-50%);
  z-index: 3;

  > div {
    margin: 0.5rem 0;
  }
`

const CTA = styled(Button)`
  position: absolute;
  bottom: 5.625rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  @media (max-width: 1199px) {
    bottom: 3.75rem;
  }
`

const Dots = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding-top: 1.875rem;

  > div {
    background: ${({ theme }) => theme.colors.variants.neutralLight5};
  }
`
